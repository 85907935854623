import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from './Layout';
import VigneronPageTitle from "./VigneronPageTitle";
import Seo from "./SEO";

import { FaPhone, FaEnvelope, FaMapPin, FaExternalLinkAlt } from "react-icons/fa";

export default function vigneronTemplate(
  data // this prop will be injected by the GraphQL query below.
) {

    const formattedDescription = data.pageContext.description.split(`\n\n`).map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`).join(``) 
    const imgEncaveur = getImage(data.pageContext.imgEncaveur);
    const imgCave = getImage(data.pageContext.imgCave);

  return (
      <Layout>
          <Seo title={data.pageContext.name} />
          <div className="main-container">
              <div className="vigneron-page">
                <div className="vigneron-infos">
                    <VigneronPageTitle title={data.pageContext.name} />
                        <p dangerouslySetInnerHTML={{__html: formattedDescription}}></p>
                        
                        <ul>
                            <li><FaPhone />{data.pageContext.numero}</li>
                            {/* display seulement si ces numéros optionnels ont été entrés dans netlifycms */}
                            {data.pageContext.numero2 && <li className="optional-number">{data.pageContext.numero2}</li>}
                            {data.pageContext.numeroCave && <li className="optional-number">{data.pageContext.numeroCave}</li>}
                            <li><FaMapPin />{data.pageContext.adresse}</li>
                            <li>
                                <a href={`https://${data.pageContext.site}`} target="_blank" rel="noreferrer">
                                    <FaExternalLinkAlt />{data.pageContext.site}
                                </a>
                            </li>
                            <li>
                                <a href={`mailto:${data.pageContext.mail}`} >
                                    <FaEnvelope />{data.pageContext.mail}
                                </a>
                            </li>
                        </ul>     
                </div>
                <div className="vigneron-portrait">
                    <GatsbyImage image={imgEncaveur} alt={data.pageContext.name} />
                </div>
              </div>
          </div>

          <div className="grey-bg">
              <div className="main-container">
                <h2 className="section-title">
                  Cépages
                </h2>
                <div className="cepages-parent">
                    <p className="cepages-vigneron">
                        {data.pageContext.blancs.replace(/;/g, ' - ')}
                    </p>
                    <p className="cepages-vigneron">
                        {data.pageContext.rouges.replace(/;/g, '   -   ')}
                    </p>
                    {data.pageContext.sansSulfites &&
                    <p className="cepages-vigneron">
                        Vins sans sulfites: 
                        {data.pageContext.sansSulfites.replace(/;/g, '   -   ')}
                    </p>}
                </div>
              </div>
          </div>
          <div className="cave-img">
              <GatsbyImage image={imgCave} alt={data.pageContext.name} />
          </div>
      </Layout>
  )
}