import React from 'react';
import useWindowSize from '../utils/useWindowSize';

const VigneronPageTitle = ({ title }) => {
    const { width } = useWindowSize();
    const wordsTitle = title.split(' ');

    // S'il y a plus de 3 mots, couper 
    const firstLine = wordsTitle.length > 2 ? wordsTitle.slice(0, 2).join(' ') : ''
    const secondLine = wordsTitle.length > 2 ? wordsTitle.slice(2, wordsTitle.length).join(' ') : wordsTitle.join(' ')
    const mobileTitle = wordsTitle.map((word) => { return(<h1 className="page-title-mobile">{word}</h1>)})
    return(
            <div>
                {width <= 568 && 
                    <div>
                        {mobileTitle}
                    </div>
                }
 
                { width > 568 && <div>
                {wordsTitle.length > 2 && <h1 className="page-title">{firstLine}</h1>}
                {wordsTitle.length > 2 && <h1 id="second-line" className="page-title">{secondLine}</h1>}
                {wordsTitle.length <= 2 && <h1 className='page-title'>{secondLine}</h1>}</div>}
            </div>
    )
    
}

export default VigneronPageTitle;